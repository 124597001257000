import React from 'react';
import { useNavigate } from 'react-router-dom';

// Define the type for the Subscription prop
interface Subscription {
  image: string;
  name: string;
}

interface SubscriptionCardProps {
  Subscription: Subscription;
}

const SubscriptionCard: React.FC<SubscriptionCardProps> = ({ Subscription }) => {
  const navigate = useNavigate(); // Call useNavigate hook

  return (
    <div className="bg-white rounded-lg shadow-lg w-[300px] mb-6 p-6 text-center">
      <img src={Subscription.image} alt={Subscription.name} className="w-full h-36 object-cover rounded-lg" />
      <div className="mt-4 flex justify-between">
        <button
          className="bg-red-500 text-white py-2 px-4 rounded-lg hover:bg-red-600 w-1/2 mr-2"
          onClick={() => navigate('/Subscription-branch')}
        >
          View
        </button>
        <button className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 w-1/2">
          Edit
        </button>
      </div>
    </div>
  );
};

export default SubscriptionCard;
