import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import './GymCard.css'; // Optional CSS for card styling
import { useNavigate } from 'react-router-dom';

// Define the type for the gym prop
interface Gym {
  image: string;
  name: string;
  description: string;
  location: string;
}

interface GymBranchCardProps {
  gym: Gym;
}

const GymBranchCard: React.FC<GymBranchCardProps> = ({ gym }) => {
  const navigate = useNavigate(); // Call useNavigate hook

  return (
    <div className="bg-white rounded-lg shadow-lg w-[300px] mb-6 p-6 text-center">
      <img src={gym.image} alt={gym.name} className="gym-image" />
      <div className="gym-info">
        <div className="gym-header">
          <h3>{gym.name}</h3>
          <FontAwesomeIcon icon={faTrashAlt} className="delete-icon" />
        </div>
        <p>{gym.description}</p>
        <p>
          <span role="img" aria-label="location">📍</span> {gym.location}
        </p>
      </div>
      <div className="gym-actions">
        <button className="view-btn" onClick={() => navigate('/add-gym-subcription')}>
          View
        </button>
        <button className="edit-btn">Edit</button>
      </div>
    </div>
  );
};

export default GymBranchCard;
