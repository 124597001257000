import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

// Define the type for the gym prop
interface Gym {
  image: string;
  name: string;
  description: string;
  location: string;
}

interface GymCardProps {
  gym: Gym;
}

const GymCard: React.FC<GymCardProps> = ({ gym }) => {
  const navigate = useNavigate(); // Call useNavigate hook

  return (
    <div className="bg-white rounded-lg shadow-lg w-[300px] mb-6 p-6 text-center">
      <img src={gym.image} alt={gym.name} className="w-full h-36 object-cover rounded-lg" />
      <div className="mt-4">
        <div className="flex justify-between items-center">
          <h3 className="font-bold text-lg">{gym.name}</h3>
          <FontAwesomeIcon icon={faTrashAlt} className="text-red-500 cursor-pointer hover:opacity-80" />
        </div>
        <p className="mt-2 text-gray-600">{gym.description}</p>
        <p className="mt-2 text-gray-500">
          <span role="img" aria-label="location">📍</span> {gym.location}
        </p>
      </div>
      <div className="mt-4 flex justify-between">
        <button
          className="bg-red-500 text-white py-2 px-4 rounded-lg hover:bg-red-600 w-1/2 mr-2"
          onClick={() => navigate('/gym-branch')}
        >
          View
        </button>
        <button className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 w-1/2">
          Edit
        </button>
      </div>
    </div>
  );
};

export default GymCard;
