import React, { useState, ChangeEvent, FormEvent, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

interface GymData {
  gymName: string;
  description: string;
  country: string;
  logos: File[]; // Array of File objects
}

interface Errors {
  gymName?: string;
  description?: string;
  country?: string;
}

function GymForm() {
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement>(null); // Use ref instead of querySelector

  // Initial state with type GymData
  const [gymData, setGymData] = useState<GymData>({
    gymName: '',
    description: '',
    country: '',
    logos: [],
  });

  const [errors, setErrors] = useState<Errors>({});
  const [touched, setTouched] = useState<{ [key: string]: boolean }>({});
  const [success, setSuccess] = useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);

  const validate = useCallback((data: GymData) => {
    let tempErrors: Errors = {};
    if (!data.gymName) tempErrors.gymName = 'Gym Name is required';
    if (!data.country) tempErrors.country = 'Country is required';
    setErrors(tempErrors);
    setIsFormValid(Object.keys(tempErrors).length === 0); // Set form validity
  }, []);
  
  // Updated handleChange to pass the latest state
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      const updatedData = { ...gymData, [name]: value };
      setGymData(updatedData);
      validate(updatedData); // Pass the updated state for validation
    },
    [gymData, validate]
  );
  
  // Updated handleFileChange to pass the latest state
  const handleFileChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files ? Array.from(e.target.files) : [];
      const updatedData = {
        ...gymData,
        logos: [...gymData.logos, ...files],
      };
      setGymData(updatedData);
      validate(updatedData); // Pass the updated state for validation
    },
    [gymData, validate]
  );
  
  // Updated handleBlur to pass the latest state
  const handleBlur = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { name } = e.target;
      setTouched((prevTouched) => ({ ...prevTouched, [name]: true }));
      validate(gymData); // Validate using the current state
    },
    [gymData, validate]
  );
  

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (!isFormValid) {
        return; // Don't submit if there are validation errors
      }
  
      // Check if images are not uploaded
      if (gymData.logos.length === 0) {
        Swal.fire({
          icon: 'warning',
          title: 'Please upload images',
          text: 'You need to upload at least one image to proceed.',
        });
        return; // Stop submission
      }
  
      // Continue with the form submission
      console.log(gymData);
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 3000);
    },
    [isFormValid, gymData]
  );
  const handleCancel = useCallback(() => {
    navigate('/add-gym'); // Navigate to another page
  }, [navigate]);

  const handleRemoveImage = useCallback(
    (index: number) => {
      const updatedLogos = gymData.logos.filter((_, i) => i !== index);
      setGymData((prevData) => ({ ...prevData, logos: updatedLogos }));

      if (updatedLogos.length === 0) {
        closeModal();
      }
    },
    [gymData, setGymData]
  );

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleNextImage = () => {
    if (currentImageIndex < gymData.logos.length - 1) {
      setCurrentImageIndex(currentImageIndex + 1);
    }
  };

  const handlePrevImage = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
    }
  };

  return (
    <div className="container mx-auto p-10">
      <form className="bg-white p-8 rounded-lg shadow-md" onSubmit={handleSubmit}>
        <h2 className="text-2xl font-bold mb-6">ADD NEW GYM BUSINESS</h2>

        {success && <div className="bg-green-500 text-white p-3 rounded mb-4">SUCCESSFULLY SAVED</div>}

        {/* Gym Name */}
        <div className="flex flex-col mb-4">
          <label className="mb-2 font-medium">Gym Name<span className="text-red-500">*</span></label>
          <input
            type="text"
            name="gymName"
            value={gymData.gymName}
            onChange={handleChange}
            onBlur={handleBlur}
            className={`p-2 border ${errors.gymName && touched.gymName ? 'border-red-500' : 'border-gray-300'} rounded-lg`}
            placeholder="Enter Gym Name"
            required
          />
          {errors.gymName && touched.gymName && <span className="text-red-500 text-sm">{errors.gymName}</span>}
        </div>

        {/* Description */}
        <div className="flex flex-col mb-4">
          <label className="mb-2 font-medium">Description</label>
          <input
            type="text"
            name="description"
            value={gymData.description}
            onChange={handleChange}
            onBlur={handleBlur}
            className={`p-2 border ${errors.description && touched.description ? 'border-red-500' : 'border-gray-300'} rounded-lg`}
            placeholder="Description"
          />
        </div>

        {/* Country */}
        <div className="flex flex-col mb-4">
          <label className="mb-2 font-medium">Country<span className="text-red-500">*</span></label>
          <input
            type="text"
            name="country"
            value={gymData.country}
            onChange={handleChange}
            onBlur={handleBlur}
            className={`p-2 border ${errors.country && touched.country ? 'border-red-500' : 'border-gray-300'} rounded-lg`}
            placeholder="Enter Country"
            required
          />
          {errors.country && touched.country && <span className="text-red-500 text-sm">{errors.country}</span>}
        </div>

        {/* Logos Upload */}
        <div className="flex flex-col mb-6">
          <label className="mb-2 font-medium">Images<span className="text-red-500">*</span></label>
          <div className="border border-dashed border-gray-300 p-4 rounded-lg text-center relative">
            <input 
              ref={fileInputRef}
              type="file" 
              name="logos" 
              onChange={handleFileChange} 
              className="hidden" 
              multiple
            />
            <span 
              className="text-gray-500 cursor-pointer"
              onClick={() => fileInputRef.current?.click()}
            >
              Click to upload images
            </span>
            {/* Display the uploaded images */}
            <div className="relative mt-4 grid grid-cols-6 gap-2">
              {gymData.logos.slice(0, 6).map((file, index) => (
                <div key={index} className="relative">
                  <img 
                    src={URL.createObjectURL(file)} 
                    alt={file.name} 
                    className="w-24 h-24 object-cover rounded-lg" 
                  />
                  {/* Close button */}
                  <button
                    type="button"
                    aria-label="Remove image"
                    className="absolute top-0 left-1/2 transform -translate-x-1/2 bg-red-500 text-white rounded-full p-1"
                    onClick={() => handleRemoveImage(index)}
                  >
                    ✕
                  </button>
                </div>
              ))}

              {/* Show "+ more" count if logos exceed 6 */}
              {gymData.logos.length > 6 && (
                <div 
                  className="absolute bottom-2 right-2 bg-gray-700 text-white rounded-full px-3 py-1 text-xs cursor-pointer"
                  onClick={openModal}
                >
                  +{gymData.logos.length - 6} more
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Buttons Section */}
        <div className="flex justify-start space-x-4">
          <button 
            type="submit" 
            className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600"
            disabled={!isFormValid}
          >
            Submit
          </button>

          <button 
            type="button" 
            className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600" 
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
      </form>

{/* Modal for viewing all images */}
          {/* Modal */}
          {showModal && (
     <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
    <div className="bg-white p-6 rounded-lg relative">
      {/* Close Button */}
      <button
        className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-2 z-50"
        onClick={closeModal}
      >
        ✕
      </button>

      {/* Image with Delete Button Positioned Below the Center */}
      <div className="relative flex flex-col items-center">
        <img
          src={URL.createObjectURL(gymData.logos[currentImageIndex])}
          alt={`Image ${currentImageIndex + 1}`}
          className="max-w-xs max-h-80 object-cover"
        />

        {/* Delete Button Positioned Outside the Image */}
        <button
          className="bg-gray-700 bg-opacity-50 rounded-full p-2 text-white mt-2"
          onClick={() => handleRemoveImage(currentImageIndex)}
        >
          🗑️
        </button>
      </div>

      <div className="flex justify-between items-center mt-4">
        {/* Previous Button */}
        <button
          className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600"
          onClick={handlePrevImage}
          disabled={currentImageIndex === 0}
        >
          Previous
        </button>

        {/* Image Count Display */}
        <span className="text-gray-700 font-medium">
          {currentImageIndex + 1} of {gymData.logos.length}
        </span>

        {/* Next Button */}
        <button
          className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600"
          onClick={handleNextImage}
          disabled={currentImageIndex === gymData.logos.length - 1}
        >
          Next
        </button>
      </div>
    </div>
  </div>
)}

    </div>
  );
}

export default GymForm;
