import React, { useState, ChangeEvent, FormEvent, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';

interface SubscriptionData {
  SubscriptionType: string;
  price: number;
}

interface Errors {
  SubscriptionType?: string;
  price?: number;
}

const options = [
    { value: 'Basic', label: 'Basic' },
    { value: 'Standard', label: 'Standard' },
    { value: 'Premium', label: 'Premium' },
  ];

function SubscriptionForm() {
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement>(null); // Use ref instead of querySelector

  // Initial state with type SubscriptionData
  const [SubscriptionData, setSubscriptionData] = useState<SubscriptionData>({
    SubscriptionType: '',
    price: 0
  });

  const [errors, setErrors] = useState<Errors>({});
  const [touched, setTouched] = useState<{ [key: string]: boolean }>({});
  const [success, setSuccess] = useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const { name, value } = e.target;
      setSubscriptionData((prevData) => ({ ...prevData, [name]: value }));
      validate(); // Validate on change
    },
    [setSubscriptionData]
  );
  
  const handleBlur = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const { name } = e.target;
      setTouched((prevTouched) => ({ ...prevTouched, [name]: true }));
      validate();
    },
    [setTouched]
  );
  

  const validate = useCallback(() => {
    let tempErrors: Errors = {};
    if (!SubscriptionData.SubscriptionType) tempErrors.SubscriptionType = 'Subscription plan is required';
    setErrors(tempErrors);
    setIsFormValid(Object.keys(tempErrors).length === 0); // Set form validity
  }, [SubscriptionData]);

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (!isFormValid) {
        return; // Don't submit if there are validation errors
      }

      console.log(SubscriptionData);
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 3000);
    },
    [isFormValid, SubscriptionData]
  );

  const handleCancel = useCallback(() => {
    navigate('/add-gym'); // Navigate to another page
  }, [navigate]);


  return (
<div className="container mx-auto p-10">
  <form className="bg-white p-8 rounded-lg shadow-md" onSubmit={handleSubmit}>
    <h2 className="text-2xl font-bold mb-6">ADD NEW SUBSCRIPTION PLAN</h2>

    {success && <div className="bg-green-500 text-white p-3 rounded mb-4">SUCCESSFULLY SAVED</div>}

    {/* Subscription Plan */}
    <div className="flex flex-col mb-4">
      <label className="mb-2 font-medium">
        Subscription Plan <span className="text-red-500">*</span>
      </label>
       <Select
        options={options}
        name="SubscriptionType"
        value={options.find(option => option.value === SubscriptionData.SubscriptionType)}
        onChange={selectedOption => {
            if (selectedOption) {
            setSubscriptionData({
                ...SubscriptionData,
                SubscriptionType: selectedOption.value,
            });
            }
        }}
        className="basic-select"
        classNamePrefix="select"
        />

      {errors.SubscriptionType && touched.SubscriptionType && (
        <span className="text-red-500 text-sm">{errors.SubscriptionType}</span>
      )}
    </div>


    <div className="flex flex-col mb-4">
        <label className="mb-2 font-medium">
        Price <span className="text-red-500">*</span>
        </label>
    <div className="relative">
    <input
      type="number"
      name="price"
      value={SubscriptionData.price}
      onChange={handleChange}
      onBlur={handleBlur}
      className={`p-2 pl-12 border ${errors.price && touched.price ? 'border-red-500' : 'border-gray-300'} rounded-lg w-full`}
      placeholder="Enter price"
      required
    />
    <span className="absolute left-3 top-2 text-gray-500">SAR</span>
   </div>
  {errors.price && touched.price && (
    <span className="text-red-500 text-sm">{errors.price}</span>
  )}
</div>


    {/* Buttons Section */}
    <div className="flex justify-start space-x-4">
      <button 
        type="submit" 
        className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600"
        disabled={!isFormValid}
      >
        Save
      </button>

      <button 
        type="button" 
        className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600" 
        onClick={handleCancel}
      >
        Cancel
      </button>
    </div>
  </form>
</div>

  );
}

export default SubscriptionForm;
