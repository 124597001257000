import React, { useState, useEffect, useRef, ChangeEvent, FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';

// Define the structure of formData
interface FormData {
  location: string;
  latitude: number | null;
  longitude: number | null;
  branchName: string;
  openingTime: string;
  closingTime: string;
  offDays: string[];
  holderName: string;
  accountNumber: string;
  bankName: string;
  branchBankName: string;
  swiftCode: string;
  gymtype:string;
  logos: File[];
}

// Define the structure of errors for form validation
interface FormErrors {
  branchName?: string;
  openingTime?: string;
  closingTime?: string;
  holderName?: string;
  accountNumber?: string;
  bankName?: string;
  branchBankName?: string;
  swiftCode?: string;
  location?: string;
  gymtype?:string;
}

// Declare google globally
declare global {
  interface Window {
    google: any;
  }
}
const AddBranch: React.FC = () => {
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement>(null); // Use ref instead of querySelector


  const [formData, setFormData] = useState<FormData>({
    location: '',
    latitude: null,
    longitude: null,
    branchName: '',
    openingTime: '',
    closingTime: '',
    offDays: [],
    holderName: '',
    accountNumber: '',
    bankName: '',
    branchBankName: '',
    swiftCode: '',
    logos: [],
    gymtype:''
  });

  const [showModal, setShowModal] = useState<boolean>(false);
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
  const [isDropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [errors, setErrors] = useState<FormErrors>({});
  const [touched, setTouched] = useState<Record<string, boolean>>({});
  const [success, setSuccess] = useState<boolean>(false);

  const autocompleteRef = useRef<HTMLInputElement>(null);
  const mapRef = useRef<HTMLDivElement>(null);

  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const dayAbbreviations: { [key: string]: string } = {
    Sunday: 'Sun',
    Monday: 'Mon',
    Tuesday: 'Tue',
    Wednesday: 'Wed',
    Thursday: 'Thu',
    Friday: 'Fri',
    Saturday: 'Sat',
  };



  const validate = () => {
    let tempErrors: FormErrors = {};
    if (!formData.branchName) tempErrors.branchName = 'Branch Name is required';
    if (!formData.openingTime) tempErrors.openingTime = 'Opening time is required';
    if (!formData.closingTime) tempErrors.closingTime = 'Closing time is required';
    if (!formData.holderName) tempErrors.holderName = 'Account holder name is required';
    if (!formData.bankName) tempErrors.bankName = 'Bank Name is required';
    if (!formData.branchBankName) tempErrors.branchBankName = 'Branch Bank Name is required';
    if (!formData.swiftCode) tempErrors.swiftCode = 'Swift code is required';
    if (!formData.accountNumber) tempErrors.accountNumber = 'Account number is required';
    if (!formData.gymtype) tempErrors.gymtype = 'Gym type is required';

    setErrors(tempErrors);
    setIsFormValid(Object.keys(tempErrors).length === 0);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    validate();
  };

  const handleBlur = (e: ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;
    setTouched((prevTouched) => ({ ...prevTouched, [name]: true }));
    validate();
  };

  useEffect(() => {
    if (window.google) {
      // Initialize Autocomplete
      const autocomplete = new window.google.maps.places.Autocomplete(autocompleteRef.current!);
      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        const location = place.geometry?.location;
        if (location) {
          setFormData((prevData) => ({
            ...prevData,
            location: place.formatted_address || '',
            latitude: location.lat(),
            longitude: location.lng(),
          }));

          const map = new window.google.maps.Map(mapRef.current!, {
            center: { lat: location.lat(), lng: location.lng() },
            zoom: 5,
          });

          new window.google.maps.Marker({
            position: { lat: location.lat(), lng: location.lng() },
            map: map,
          });
        }
      });

      // Initialize Map
      const map = new window.google.maps.Map(mapRef.current!, {
        center: { lat: 23.8859, lng: 45.0792 },
        zoom: 5,
      });

      // Add click event listener to the map
      map.addListener('click', (event: google.maps.MapMouseEvent) => {
        const clickedLocation = event.latLng;
        if (clickedLocation) {
          setFormData((prevData) => ({
            ...prevData,
            latitude: clickedLocation.lat(),
            longitude: clickedLocation.lng(),
          }));

          const geocoder = new window.google.maps.Geocoder();
          geocoder.geocode({ location: clickedLocation }, (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
            if (status === 'OK' && results[0]) {
              setFormData((prevData) => ({
                ...prevData,
                location: results[0].formatted_address || '',
              }));
            }
          });

          new window.google.maps.Marker({
            position: clickedLocation,
            map: map,
          });
        }
      });
    }
  }, [formData.latitude, formData.longitude]);

  const handleCancel = () => {
    navigate('/gym-branch');
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    setFormData((prevData) => ({
      ...prevData,
      logos: [...prevData.logos, ...files],
    }));
    validate();
  };

  const handleOffDayChange = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedDay = e.target.value;
    if (formData.offDays.includes(selectedDay)) {
      setFormData((prevData) => ({
        ...prevData,
        offDays: prevData.offDays.filter((day) => day !== selectedDay),
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        offDays: [...prevData.offDays, selectedDay],
      }));
    }
  };

  const removeOffDay = (dayToRemove: string) => {
    setFormData((prevData) => ({
      ...prevData,
      offDays: prevData.offDays.filter((day) => day !== dayToRemove),
    }));
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleRemoveImage = (index: number) => {
    const updatedLogos = formData.logos.filter((_, i) => i !== index);
    setFormData((prevData) => ({ ...prevData, logos: updatedLogos }));
    if (updatedLogos.length === 0) {
      closeModal();
    }
  };

  const handleNextImage = () => {
    if (currentImageIndex < formData.logos.length - 1) {
      setCurrentImageIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePrevImage = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isFormValid) return;
    console.log('Form data submitted:', formData);
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
    }, 3000);
  };

  return (
    <div>
    <form className="max-w-6xl mx-auto p-6 bg-white shadow-md rounded-md" onSubmit={handleSubmit}>
      <h2 className="text-lg font-semibold mb-6">Branch Information</h2>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Branch Name */}
        <div className="flex flex-col">
          <label htmlFor="branchName" className="mb-2 font-medium">Branch Name<span className="text-red-500">*</span></label>
          <input
            type="text"
            name="branchName"
            id="branchName"
            value={formData.branchName}
            onChange={handleChange}
            onBlur={handleBlur} // Track when the field is blurred
            className="p-2 border rounded-md focus:ring-2 focus:ring-green-500"
            placeholder="Enter branch name"
            required
          />
          {errors.branchName && touched.branchName && <span className="text-red-500 text-sm">{errors.branchName}</span>}

        </div>

      {/* off days*/}
      <div className="flex flex-col">
      <label htmlFor="offDays" className="mb-2 font-medium">Off Days</label>

      {/* Custom input-like container */}
      <div className="flex flex-wrap items-center p-2 border rounded-md focus-within:ring-2 focus-within:ring-green-500">
        {/* Chips for selected off days */}
        {formData.offDays.map((day) => (
          <div
            key={day}
            className="bg-green-100 text-green-700 rounded-full flex items-center mr-1 px-1"
          >
            {/* Display abbreviated day name */}
            <span>{dayAbbreviations[day]}</span>
            <button
              type="button"
              className="ml-2 text-red-500"
              onClick={() => removeOffDay(day)}
            >
              ×
            </button>
          </div>
        ))}

        {/* Dropdown button */}
        <div className="relative" ref={dropdownRef}>
          <button
            type="button"
            onClick={() => setDropdownOpen(!isDropdownOpen)}
            className="border-none focus:ring-0 bg-gray-200 text-gray-700 rounded-md"
          >
            Select Days
          </button>

          {/* Dropdown list with checkboxes */}
          {isDropdownOpen && (
            <div className="absolute z-10 mt-2 p-4 bg-white border rounded-md shadow-lg">
              {daysOfWeek.map((day) => (
                <div key={day} className="flex items-center">
                  <input
                    type="checkbox"
                    id={day}
                    value={day}
                    checked={formData.offDays.includes(day)}
                    onChange={handleOffDayChange}
                    className="mr-2"
                  />
                  <label htmlFor={day}>{day}</label>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      </div>

      {/* Opening Time */}
      <div className="flex flex-col">
        <label htmlFor="openingTime" className="mb-2 font-medium">Opening Time<span className="text-red-500">*</span></label>
        <input
          type="time"  // Change type to 'time' for time picker
          name="openingTime"
          id="openingTime"
          value={formData.openingTime}
          onChange={handleChange}
          onBlur={handleBlur} // Track when the field is blurred
          className="p-2 border rounded-md focus:ring-2 focus:ring-green-500"
          required
        />
        {errors.openingTime && touched.openingTime && <span className="text-red-500 text-sm">{errors.openingTime}</span>}

      </div>

      {/* Closing Time */}
      <div className="flex flex-col">
        <label htmlFor="closingTime" className="mb-2 font-medium">Closing Time<span className="text-red-500">*</span></label>
        <input
          type="time"  // Change type to 'time' for time picker
          name="closingTime"
          id="closingTime"
          value={formData.closingTime}
          onBlur={handleBlur} // Track when the field is blurred
          onChange={handleChange}
          className="p-2 border rounded-md focus:ring-2 focus:ring-green-500"
          required
        />
        {errors.closingTime && touched.closingTime && <span className="text-red-500 text-sm">{errors.closingTime}</span>}
      </div>
      </div>

       {/* gymtype */}
          <div className="flex flex-col mt-6">
          <label className="mb-2 font-medium">Gym Type<span className="text-red-500">*</span></label>
          <input
            type="text"
            name="gymtype"
            value={formData.gymtype}
            onChange={handleChange}
            onBlur={handleBlur}
            className={`p-2 border ${errors.gymtype && touched.gymtype ? 'border-red-500' : 'border-gray-300'} rounded-lg`}
            placeholder="Enter gym type"
            required
          />
          {errors.gymtype && touched.gymtype && <span className="text-red-500 text-sm">{errors.gymtype}</span>}
        </div>

      <div>
      {/* Location Input */}
      <div className="flex flex-col mt-6">
        <label htmlFor="location" className="mb-2 font-medium">
          Select Location<span className="text-red-500">*</span>
        </label>
        <input
          ref={autocompleteRef} // Attach ref for Autocomplete
          type="text"
          name="location"
          id="location"
          value={formData.location}
          onChange={handleChange}
          onBlur={handleBlur} // Track when the field is blurred
          className="p-2 border rounded-md focus:ring-2 focus:ring-green-500"
          placeholder="Enter city name"
          required
        />
        {errors.location && touched.location && (
          <span className="text-red-500 text-sm">{errors.location}</span>
        )}
      </div>

      {/* Display Coordinates */}
      {formData.latitude && formData.longitude && (
        <div className="mt-4" style={{ display: 'none' }}>
          <p>Latitude: {formData.latitude}</p>
          <p>Longitude: {formData.longitude}</p>
        </div>
      )}

      {/* Map */}
      <div
        ref={mapRef} // Attach ref for the map
        style={{ height: '400px', width: '100%', marginTop: '20px' }}
      ></div>
    </div>

        {/* Logos Upload */}
        <div className="flex flex-col mb-6">
          <label className="mb-2 font-medium">Images<span className="text-red-500">*</span></label>
          <div className="border border-dashed border-gray-300 p-4 rounded-lg text-center relative">
            <input 
              ref={fileInputRef}
              type="file" 
              name="logos" 
              onChange={handleFileChange} 
              className="hidden" 
              multiple
            />
            <span 
              className="text-gray-500 cursor-pointer"
              onClick={() => fileInputRef.current?.click()}
            >
              Click to upload images
            </span>
            {/* Display the uploaded images */}
            <div className="relative mt-4 grid grid-cols-6 gap-2">
              {formData.logos.slice(0, 6).map((file, index) => (
                <div key={index} className="relative">
                  <img 
                    src={URL.createObjectURL(file)} 
                    alt={file.name} 
                    className="w-24 h-24 object-cover rounded-lg" 
                  />
                  {/* Close button */}
                  <button
                    type="button"
                    aria-label="Remove image"
                    className="absolute top-0 left-1/2 transform -translate-x-1/2 bg-red-500 text-white rounded-full p-1"
                    onClick={() => handleRemoveImage(index)}
                  >
                    ✕
                  </button>
                </div>
              ))}

              {/* Show "+ more" count if logos exceed 6 */}
              {formData.logos.length > 6 && (
                <div 
                  className="absolute bottom-2 right-2 bg-gray-700 text-white rounded-full px-3 py-1 text-xs cursor-pointer"
                  onClick={openModal}
                >
                  +{formData.logos.length - 6} more
                </div>
              )}
            </div>
          </div>
        </div>




      {/* Bank Details Section */}
      <h2 className="text-lg font-semibold mt-8 mb-6">Bank Details</h2>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Holder Name */}
        <div className="flex flex-col">
          <label htmlFor="holderName" className="mb-2 font-medium">Holder Name<span className="text-red-500">*</span></label>
          <input
            type="text"
            name="holderName"
            id="holderName"
            value={formData.holderName}
            onChange={handleChange}
            onBlur={handleBlur} // Track when the field is blurred
            className="p-2 border rounded-md focus:ring-2 focus:ring-green-500"
            placeholder="Enter holder name"
            required
          />
          {errors.holderName && touched.holderName && <span className="text-red-500 text-sm">{errors.holderName}</span>}

        </div>

        {/* Account Number */}
        <div className="flex flex-col">
          <label htmlFor="accountNumber" className="mb-2 font-medium">Account Number<span className="text-red-500">*</span></label>
          <input
            type="text"
            name="accountNumber"
            id="accountNumber"
            value={formData.accountNumber}
            onChange={handleChange}
            onBlur={handleBlur} // Track when the field is blurred
            className="p-2 border rounded-md focus:ring-2 focus:ring-green-500"
            placeholder="Enter account number"
            required
          />
          {errors.accountNumber && touched.accountNumber && <span className="text-red-500 text-sm">{errors.accountNumber}</span>}

        </div>

        {/* Bank Name */}
        <div className="flex flex-col">
          <label htmlFor="bankName" className="mb-2 font-medium">Bank Name<span className="text-red-500">*</span></label>
          <input
            type="text"
            name="bankName"
            id="bankName"
            value={formData.bankName}
            onChange={handleChange}
            onBlur={handleBlur} // Track when the field is blurred
            className="p-2 border rounded-md focus:ring-2 focus:ring-green-500"
            placeholder="Enter bank name"
            required
          />
          {errors.bankName && touched.bankName && <span className="text-red-500 text-sm">{errors.bankName}</span>}

        </div>

        {/* Branch Bank Name */}
        <div className="flex flex-col">
          <label htmlFor="branchBankName" className="mb-2 font-medium">Branch Name<span className="text-red-500">*</span></label>
          <input
            type="text"
            name="branchBankName"
            id="branchBankName"
            value={formData.branchBankName}
            onChange={handleChange}
            onBlur={handleBlur} // Track when the field is blurred
            className="p-2 border rounded-md focus:ring-2 focus:ring-green-500"
            placeholder="Enter branch name"
            required
          />
          {errors.branchBankName && touched.branchBankName && <span className="text-red-500 text-sm">{errors.branchBankName}</span>}

        </div>

        {/* SWIFT Code */}
        <div className="flex flex-col">
          <label htmlFor="swiftCode" className="mb-2 font-medium">SWIFT Code<span className="text-red-500">*</span></label>
          <input
            type="text"
            name="swiftCode"
            id="swiftCode"
            value={formData.swiftCode}
            onChange={handleChange}
            onBlur={handleBlur} // Track when the field is blurred
            className="p-2 border rounded-md focus:ring-2 focus:ring-green-500"
            placeholder="Enter SWIFT code"
            required
          />
          {errors.swiftCode && touched.swiftCode && <span className="text-red-500 text-sm">{errors.swiftCode}</span>}

        </div>

      </div>

      {/* Submit Button */}
      <button
        type="submit"
        className="mt-6 bg-green-500 text-white py-2 px-4 rounded-md hover:bg-green-600"
        disabled={!isFormValid}
      >
        Submit
      </button>
      <button 
            type="button" 
            className="bg-red-500 text-white ml-2 px-4 py-2 rounded-lg hover:bg-red-600" 
            onClick={handleCancel}
          >
            Cancel
          </button>
    </form>

          {/* Modal */}
    {showModal && (
     <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
    <div className="bg-white p-6 rounded-lg relative">
      {/* Close Button */}
      <button
        className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-2 z-50"
        onClick={closeModal}
      >
        ✕
      </button>

      {/* Image with Delete Button Positioned Below the Center */}
      <div className="relative flex flex-col items-center">
        <img
          src={URL.createObjectURL(formData.logos[currentImageIndex])}
          alt={`Image ${currentImageIndex + 1}`}
          className="max-w-xs max-h-80 object-cover"
        />

        {/* Delete Button Positioned Outside the Image */}
        <button
          className="bg-gray-700 bg-opacity-50 rounded-full p-2 text-white mt-2"
          onClick={() => handleRemoveImage(currentImageIndex)}
        >
          🗑️
        </button>
      </div>

      <div className="flex justify-between items-center mt-4">
        {/* Previous Button */}
        <button
          className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600"
          onClick={handlePrevImage}
          disabled={currentImageIndex === 0}
        >
          Previous
        </button>

        {/* Image Count Display */}
        <span className="text-gray-700 font-medium">
          {currentImageIndex + 1} of {formData.logos.length}
        </span>

        {/* Next Button */}
        <button
          className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600"
          onClick={handleNextImage}
          disabled={currentImageIndex === formData.logos.length - 1}
        >
          Next
        </button>
      </div>
    </div>
  </div>
)}



    </div>
  );
};

export default AddBranch;
