import React from 'react';
import './Sidebar.css';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import profilePicture from '../assets/profile.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import welcomeImage from '../assets/gym.jpeg';
import welcome from '../assets/welcomeI.jpg';
import { useNavigate } from 'react-router-dom';
import SubscriptionCard from './SubscriptionCard';
import './GymCard.css';

// Define the type for the gym object
interface Subscription {
  id: number;
  image: string;
  name: string;
}

const AddSubcription: React.FC = () => {
  const navigate = useNavigate(); // Call useNavigate hook

  // Sample data for subscription
  const subscription: Subscription[] = [
    {
      id: 1,
      image: welcomeImage, // Placeholder image, replace with your actual gym images
      name: 'Power World',

    },
    {
      id: 2,
      image: welcomeImage,
      name: 'Power World 2',

    },
    {
      id: 3,
      image: welcomeImage,
      name: 'Power World 3',

    },
  ];

  return (
    <React.Fragment>
      {/* Header with search, button, icons */}
      <div className="header">
        <input type="text" placeholder="Search" className="search-bar" />
        <div className="header-right">
          <button className="add-gym-button" onClick={() => navigate('/subcription-form')}>
            ADD SUBSCRIPTION
          </button>
          <FontAwesomeIcon icon={faBell} className="icon-bell" />
          <img src={profilePicture} alt="Profile" className="profile-picture" />
        </div>
      </div>

    <div >
        <h2 className="flex justify-center text-2xl font-bold">Existing Subscription Plans</h2>
    </div>



      {/* Gym Cards Section */}
      <div className="gym-cards-container">
        {subscription.map(subscription => (
          <SubscriptionCard key={subscription.id} Subscription={subscription} />
        ))}
      </div>
    </React.Fragment>
  );
};

export default AddSubcription;
